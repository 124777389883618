<template>
    
        
    <el-container class="home-container">
        <!-- 头部 -->
  <el-header height="60px"> 
      <div>
          <span>主页</span>
      </div>
      <el-button type="info" size="mini"  @click="logout">退出</el-button>
  </el-header>
        <!-- 主体 -->
  <el-container>
        <!-- 左侧边栏 -->
        <!-- // width="13%" -->
    <el-aside :width="isCollapse?'64px':'200px'">
        <div class="wchange" @click="wcclass">
            |||
        </div>
        <el-menu
        active-text-color="#1989fa"
        background-color="#212529"
        router
        :default-active="activeIndex"
        text-color="#909294"
        :collapse="isCollapse"
        :collapse-transition="false"
      >
        <el-submenu index="1">
          <template #title>
            <i class="el-icon-s-platform"></i>
            <span style="font-size:16px">仪表盘</span>
          </template>
          <el-menu-item-group >
              
             <el-menu-item index="dashboard" @click="saveIndex('dashboard')">
                <template>
                    <i class="el-icon-s-data"></i>
                    <span>总览</span>
                </template>
            </el-menu-item> 
              
            <el-menu-item index="seller" @click="saveIndex('seller')">
                <template>
                    <i class="el-icon-s-data"></i>
                    <span>月销量统计</span>
                </template>
            </el-menu-item>

            <el-menu-item index="rank" @click="saveIndex('rank')">
                <template>
                    <i class="el-icon-s-data"></i>
                    <span>月销售额统计</span>
                </template>
            </el-menu-item>

            <el-menu-item index="hot" @click="saveIndex('hot')">
                <template>
                    <i class="el-icon-s-data"></i>
                    <span>订单状态比例</span>
                </template>
            </el-menu-item>

            <el-menu-item index="trend" @click="saveIndex('trend')">
                <template>
                    <i class="el-icon-s-data"></i>
                    <span>销量趋势</span>
                </template>
            </el-menu-item>
            
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="2">
          <template #title>
            <i class="el-icon-user"></i>
            <span style="font-size:16px">用户管理</span>
          </template>
          <el-menu-item-group >
              
              
            <el-menu-item index="userlist" @click="saveIndex('userlist')">
                <template>
                    <i class="el-icon-s-custom"></i>
                    <span>用户列表</span>
                </template>
            </el-menu-item>
            
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="3">
            <template #title>
             <i class="el-icon-s-order"></i>
             <span style="font-size:16px">订单管理</span>
            </template>
          <el-menu-item-group >
            <el-menu-item index="change" @click="saveIndex('change')">
                <template>
                    <i class="el-icon-s-grid"></i>
                    <span>订单列表</span>
                </template>
            </el-menu-item>


                
            
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </el-aside>
        <!-- 右侧内容 -->
    <el-main>
        <router-view></router-view>
    </el-main>
  </el-container>
</el-container>
</template>

<script>

export default {
    data() {
        return{
            activeIndex: '',
            isCollapse: false,
        }
    },
    created(){
        this.activeIndex = window.localStorage.getItem('activeIndex')
    },
    methods: {
        logout(){
            window.sessionStorage.clear();
            this.$router.replace("/login")
            window.localStorage.setItem('activeIndex',"dashboard")
        },
        saveIndex(index){
            window.localStorage.setItem('activeIndex',index)
            this.activeIndex = index
        },
        wcclass(){
            this.isCollapse = ! this.isCollapse;
        }
    }
}
</script>

<style lang='css' scoped>
    .el-header{
        background-color:#212529;
        display:flex;
        justify-content: space-between;
        align-items: center;
        color:rgb(245, 245, 245);
        font-size:20px;
        padding-right: 50px;
        font-family:"Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
    }
    .el-aside{
        background-color:#212529;}
        .el-menu{
            border-right:0;
        }
    
    .home-container{
        height: 100%;
        font-family: '微软雅黑';
    }
    
    .el-main{
        background-color:rgba(160, 160, 160, 0.219);
    }
    
    .wchange{
        background-color:rgb(49, 54, 65);
        font-size:10px;
        line-height:24px;
        color:white;
        text-align:center;
        letter-spacing:0.2em;
        cursor: pointer;
        }

</style>