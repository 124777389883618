<template>
  <div class="com-container">
      <div class="com-chart" ref="seller_ref" v-loading="loading"></div>
  </div>
</template>

<script>

export default {
    data(){
        return{
            chartInstance:null,
            allData:[
                {name:'test1',value:"33"},
                {name:'test2',value:44},
                {name:'test3',value:55},
                {name:'test4',value:66},
                {name:'test5',value:77},
                {name:'test6',value:88},
                {name:'test7',value:99},
                {name:'test8',value:150},
                {name:'test9',value:200},
                {name:'test10',value:250},
                {name:'test11',value:300},
                {name:'test12',value:500},
                {name:'test13',value:600},
                {name:'test14',value:700},
                {name:'test15',value:1000},
            ],
            currentPage:1,//当前页数
            totalPage:0, //共几页
            timeId:null,//定时器标识
            loading:true,
        }
    },
    mounted() {
        this.initChart()
        this.getData()
        window.addEventListener('resize',this.screenAdapter)
        this.screenAdapter()
    },
    destroyed(){
        clearInterval(this.timeId)
        window.removeEventListener('resize',this.screenAdapter)
    },
    methods:{
        initChart(){
            this.chartInstance = this.$echarts.init(this.$refs.seller_ref)
            const initOption = {
                backgroundColor:'#fff',
                 title: {
                    text:'▎月销售量统计',
                    textStyle: {
                        fontSize:66 ,
                    },
                    left:60,
                    top:'8%',
                },
                grid:{
                    top:'20%',
                    left:'15%',
                    right:'6%',
                    bottom:'9%',
                    containLable:true
                },
                  
                xAxis: {
                    type:'value',

                },
                yAxis: {
                    type:'category',
                },
                
                series: [{
                    type:'bar',
                    barWidth:66,
                    label:{
                        show:true,
                        position:'right',
                        textStyle: {
                            color:'white'
                        }
                    },
                    itemStyle:{
                        borderRadius:[0,33,33,0],
                        //x1,y1,x2,y2 从左往右
                        color:new this.$echarts.graphic.LinearGradient(0,0,1,0,[
                            {   offset:0,
                                color:'#5052EE'
                            },
                            {
                                offset:1,
                                color:'#AB6EE5'
                            },

                        ])
                    },

                }],

            }

            this.chartInstance.setOption(initOption)
            
            this.chartInstance.on('mouseover',()=>{
                clearInterval(this.timeId)
            })
            this.chartInstance.on('mouseout',()=>{
                this.startInterval()
            })
        },
       async getData(){
            //请求数据
            // this.allData = [],
            const {data:res} = await this.$http.get('mnum',"aaa")
            this.allData = res.data
            this.totalPage =Math.ceil(this.allData.length/6)
            //从大到小排序

            this.updateChart()
            //启动时定时器
            this.startInterval()
            this.loading=false
            
        },
        updateChart(){
            const start = (this.currentPage-1) * 6 //每页五个
            const end = this.currentPage * 6
            const showData = this.allData.slice(start,end)
            const sellernames = showData.map((item)=>{
                return item.name
            })

            const sellervalues = showData.map((item)=>{
                return item.value
            })

            const dataoption = {        
                yAxis: {
                    data:sellernames
                }, 
                series: [{
                    data: sellervalues,
                }],

            }
            this.chartInstance.setOption(dataoption)

        },
        //刷新数据
        startInterval(){
            if(this.timeId){
                clearInterval(this.timeId)
            }
            this.timeId = setInterval(()=>{
                this.currentPage++
                if(this.currentPage>this.totalPage){
                    this.currentPage = 1
                }
                this.updateChart()
            },3000)
        },
        screenAdapter(){
            const titleFontSize = this.$refs.seller_ref.offsetWidth / 100 * 3.6
            const adapterOption = { title: {
                    textStyle: {
                        fontSize:titleFontSize ,
                    },
                },
                tooltip:{

                    axisPointer: {
                        lineStyle: {
                            width:titleFontSize,
                        }
                    }
                },
                series: [{
                    barWidth:titleFontSize,
                    itemStyle:{
                        borderRadius:[0,titleFontSize/2,titleFontSize/2,0],
                    },
                }],
                }
            this.chartInstance.setOption(adapterOption)
            this.chartInstance.resize()
        }
    },
}
</script>

<style>

</style>