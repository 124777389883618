<template>
    <div class="com-container" >
        <div class="com-chart" ref='rank_ref' v-loading="loading">

        </div>
    </div>
</template>

<script>
export default {
data(){
    return{
        chartInstance: null,
        allData:[],
        startValue:0,
        endValue:9,
        timeId:null,
        loading:true,
    }
},
mounted(){
    this.initChart()
    this.getData()
    window.addEventListener('resize',this.screenAdapter)
    this.screenAdapter()
},
destroyed(){
        window.removeEventListener('resize',this.screenAdapter)
        clearInterval(this.timeId)
    },
methods:{
    initChart(){
        this.chartInstance = this.$echarts.init(this.$refs.rank_ref)
        const initOption = {
            backgroundColor:'#fff',
           title: {
                    text:'▎月销售额统计',
                    textStyle: {
                        fontSize:66 ,
                    },
                    left:60,
                    top:'8%',
                },
            grid:{
                top:'35%',
                left:'15%',
                right:'6%',
                bottom:'15%',
                containLable:true
            },
            tooltip:{
                show: true
            },
            xAxis: {
                type:'category',
                axisLabel:{
                    rotate:40
                }
            },
            yAxis: {
                type:'value',
                name:'price'
            },
            series:[
                {
                    type:'bar'
                }
            ]
        }
        this.chartInstance.setOption(initOption)
        this.chartInstance.on('mouseover',()=>{
            clearInterval(this.timeId)
        })
        this.chartInstance.on('mouseout',()=>{
            this.startInterval()
        })
    },
    async getData(){
        //获取数据
        const {data:res} = await this.$http.get('monthprice')
        this.allData = res.data
        this.updateChart()
        this.startInterval()
        this.loading=false;
    },
    updateChart(){
        const nameArr = this.allData.map(item=>{
            return item.name
        })
        const valueArr = this.allData.map(item =>{
            return item.price
        })

        const dataOption = {
            xAxis:{
                data:nameArr
            },
            dataZoom:{
                show: false,
                startValue:this.startValue,
                endValue:this.endValue,
            },
            series:[
                {
                    data:valueArr,
                    itemStyle: {
                        color:new this.$echarts.graphic.LinearGradient(0,0,0,1,[
                            {   offset:0,
                                color:'rgba(82,229,231,1)'
                            },
                            {
                                offset:1,
                                color:'rgba(22,15,184,0.8)'
                            },
             ]) }
                }
            ]
        }
        this.chartInstance.setOption(dataOption)
    },
    screenAdapter(){
        const titleFontSize = this.$refs.rank_ref.offsetWidth/100*3.6
        const adapterOption = {
            title: {
                textStyle:{
                    fontSize:titleFontSize
                }
                },
                series:[
                {
                    barWidth:titleFontSize,
                    itemStyle:{
                        borderRadius:[titleFontSize/2,titleFontSize/2,0,0],
                    },
                }
                ]
        }
        this.chartInstance.setOption(adapterOption)
        this.chartInstance.resize()
    },
    startInterval(){
        if(this.timeId){
            clearInterval(this.timeId)
        }
        this.timeId = setInterval(()=>{
            this.startValue++
            this.endValue++
            if(this.endValue > this.allData.length - 1){
                this.startValue = 0
                this.endValue = 9
            }
            this.updateChart()
        },2000)
    }    
}
}
</script>

<style>

</style>