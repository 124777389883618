import Vue from 'vue'
import {DropdownMenu,DropdownItem,Select,Dropdown,DatePicker,Option,Button,Form,MessageBox,InputNumber,Divider,Link,Switch,Upload,FormItem,Loading,Input,Message,Dialog,Row,Pagination,Tooltip,Col,Table,TableColumn,Container,Header,Card,Aside,Main,Menu,Breadcrumb,BreadcrumbItem,Submenu,MenuItemGroup,MenuItem,Icon} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'


Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Form)
Vue.use(Button)
Vue.use(Container)
Vue.use(Switch)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItemGroup)
Vue.use(MenuItem)
Vue.use(Icon)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Card)
Vue.use(Row)
Vue.use(Col)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tooltip)
Vue.use(Pagination)
Vue.use(Loading)
Vue.use(Dialog)
Vue.use(InputNumber)
Vue.use(Upload)
Vue.use(Divider)
Vue.use(Option)
Vue.use(Link)
Vue.use(Select)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(DatePicker)

Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm