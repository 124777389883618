<template>
    <div class="space">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>订单管理</el-breadcrumb-item>
            <el-breadcrumb-item>文件传输</el-breadcrumb-item> 
        </el-breadcrumb>

        <el-card>
          <div style="margin-bottom:20px;color:#606266;">上传文件至HADOOP</div>
            <div>


      <!-- :on-remove="handleRemove"
  :before-remove="beforeRemove" -->
  
                <el-upload
  class="upload-demo"
  action="https://zhender.com/files"
  multiple
  :limit="3"
  :on-exceed="handleExceed"
  :file-list="fileList"
  :on-success="handleSuccess"
  >
  <el-button size="small" type="primary">点击上传<i class="el-icon-upload el-icon--right"></i></el-button>
  <div slot="tip" class="el-upload__tip">提示：文件内容必须按规定格式写入</div>
</el-upload>    
    </div>
  </el-card>
  <el-card>
    <div style="margin-bottom:20px;color:#606266;">HADOOP文件</div>
    <el-table :data="reslist" v-loading="loading">
                <el-table-column type="index"></el-table-column>
                <el-table-column label="文件名" prop="filename"></el-table-column>
                
                <el-table-column label="操作">
                    <!-- 按钮slot-scope="scope" -->
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="查看" placement="top" :enterable="false">
                            <el-button type="primary" size="mini"  icon="el-icon-reading" @click="showFile(scope.row.filename)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="统计" placement="top" :enterable="false">
                            <el-button type="primary" size="mini"  icon="el-icon-cpu" @click="comFile(scope.row.filename)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="下载" placement="top" :enterable="false">
                            
                            <el-link target="_blank" download="" :href="scope.row.path" :underline="false" style="margin-left:15px">
                              <el-button type="primary" size="mini"  icon="el-icon-download" ></el-button>
                            </el-link>
                        </el-tooltip>
                        
                    </template>
                </el-table-column>

            </el-table>
    </el-card>

        
            <el-dialog
        title="文件内容"
        :visible.sync="readVisible"
        width="50%"
        @close="readClosed"
        >
            
                  <el-input
            type="textarea"
            autosize
            disabled
            v-loading="readloading"
            v-model="text"
            >
            </el-input>
        </el-dialog>
          
    </div>
</template>

<script>


export default {
    data() {
      return {
        fileList: [],
        reslist:[
          
        ],
        loading:true,
        text:"",
        readVisible:false,
        readloading:true,
      };
    },
    created() {
        this.listFile()
    },
    methods: {
      handleExceed(files, fileList) {
        this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      },
      async showFile(filename){
            this.readVisible=true;
            this.readloading=true;
            const {data:res} = await this.$http.get('filecheck',{ params: {"filename":filename}})
            this.text = res.text;
            this.readloading=false;

      },
      async listFile(){
            //this.loading = true;
            const {data:res} = await this.$http.get('files',{ params: ""})
            this.reslist = res.reslist;
            this.loading = false;
            console.log(res)
      },
      handleSuccess(response, file, fileList){
          this.listFile()
      },

      async comFile(filename){
            this.readVisible=true;
            this.readloading=true;
            const {data:res} = await this.$http.get('comfile',{ params: {"filename":filename}})
            this.reslist = res.reslist;
            this.text = res.text;
            this.readloading=false;
      },
       
      readClosed(){
        this.readloading=true;
        this.readVisible=false;
        this.text=""
        this.listFile()
      }
    }
}
</script>

<style lang="less" scoped>
.el-card {
  margin-bottom: 20px;
}
</style>